import '../styles/globals.css'
import type { AppProps } from 'next/app';
import Script from 'next/script';


function MyApp({ Component, pageProps }: AppProps) {

  return (
    <>
      <Script
        id="crisp"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="a244c712-2218-4fa6-8956-134c9eb40094";(function(){d = document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.defer=1;d.getElementsByTagName("head")[0].appendChild(s);})();
          `,
        }}
      />
      <Script
        id="hotjar"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3414123,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
